<template>
  <page-loader :loading="fetching">
    <b-overlay :show="loading">
      <div
        ref="simulationWrap"
        class="simulation-wrap"
        :style="{
          height: `${wrapHeight}px`,
        }"
      >
        <template v-if="localSimulation">
          <div class="top-content">
            <b-card class="d-flex flex-row" no-body>
              <div
                class="w-50 h-100"
                :class="{
                  'w-100': localSimulation.simulationItems.length == 0,
                }"
              >
                <waste-handling-siumlation-graph
                  :localSimulation="localSimulation"
                />
              </div>
              <div
                class="w-50 h-20 d-flex flex-column"
                v-if="localSimulation.simulationItems.length > 0"
              >
                <span class="d-flex align-items-center p-1">
                  <feather-icon icon="CalendarIcon" class="mr-1" size="20" />
                  <date-range-picker
                    ref="picker"
                    v-model="dateRange"
                    opens="left"
                    :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"
                    :append-to-body="true"
                    @update="refreshSimulation"
                  >
                  </date-range-picker>
                </span>
                <div
                  class="w-100 h-50 d-flex flex-column align-items-center justify-content-center p-1"
                >
                  <b-table-simple small class="sumTable text-center">
                    <b-thead>
                      <b-tr class="toggle_headers">
                        <b-th>{{
                          T(
                            "Web.SimluationsPage.WasteHandlingSimulation.SumTable.BaseLineSource",
                            "Baseline soruce"
                          )
                        }}</b-th>
                        <b-th>{{
                          T(
                            "Web.SimluationsPage.WasteHandlingSimulation.SumTable.RentPrMonth",
                            "Rent / month"
                          )
                        }}</b-th>
                        <b-th>{{
                          T(
                            "Web.SimluationsPage.WasteHandlingSimulation.SumTable.IncomePrTons",
                            "Income / month"
                          )
                        }}</b-th>
                        <b-th>{{
                          T(
                            "Web.SimluationsPage.WasteHandlingSimulation.SumTable.ChargePrTons",
                            "Charge / month"
                          )
                        }}</b-th>
                        <b-th>{{
                          T(
                            "Web.SimluationsPage.WasteHandlingSimulation.SumTable.PricePrCollection",
                            "Price / collection"
                          )
                        }}</b-th>
                      </b-tr>
                      <b-tr>
                        <b-th>
                          <b-form-select
                            size="sm"
                            :options="[
                              { value: 'LastInvoiced', text: 'Last invoiced' },
                              { value: 'PriceList', text: 'Price list' },
                            ]"
                            v-model="localSimulation.baselineSource"
                            @change="updateBaselineSource"
                          >
                          </b-form-select>
                        </b-th>
                        <b-th>
                          <b-form-select
                            @change="
                              toggleSimulationOptionForAll(
                                'rentSimulationOption',
                                rentToggleOption
                              )
                            "
                            v-model="rentToggleOption"
                            :options="simulatedOptions"
                            size="sm"
                          >
                            <template #first>
                              <b-form-select-option :value="''" disabled>
                                {{ T("Web.Generic.Toggle", "Toggle") }}
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-th>
                        <b-th>
                          <b-form-select
                            @change="
                              toggleSimulationOptionForAll(
                                'incomeSimulationOption',
                                incomeToggleOption
                              )
                            "
                            v-model="incomeToggleOption"
                            :options="simulatedOptions"
                            size="sm"
                          >
                            <template #first>
                              <b-form-select-option :value="''" disabled>
                                {{ T("Web.Generic.Toggle", "Toggle") }}
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-th>
                        <b-th>
                          <b-form-select
                            @change="
                              toggleSimulationOptionForAll(
                                'chargeSimulationOption',
                                chargeToggleOption
                              )
                            "
                            v-model="chargeToggleOption"
                            :options="simulatedOptions"
                            size="sm"
                          >
                            <template #first>
                              <b-form-select-option :value="''" disabled>
                                {{ T("Web.Generic.Toggle", "Toggle") }}
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-th>
                        <b-th>
                          <b-form-select
                            @change="
                              toggleSimulationOptionForAll(
                                'collectionCostSimulationOption',
                                priceToggleOption
                              )
                            "
                            v-model="priceToggleOption"
                            :options="simulatedOptions"
                            size="sm"
                          >
                            <template #first>
                              <b-form-select-option :value="''" disabled>
                                {{ T("Web.Generic.Toggle", "Toggle") }}
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody> </b-tbody>
                  </b-table-simple>
                </div>
                <div
                  class="w-100 h-80 d-flex flex-column align-items-center justify-content-center p-1"
                >
                  <b-table-simple small class="sumTable" v-if="showTable">
                    <b-thead>
                      <b-tr>
                        <b-th>
                          <b-button
                            class="addExpenseBtn"
                            variant="secondary"
                            size="sm"
                            @click="addExpense"
                          >
                            {{
                              T(
                                "Web.SimluationsPage.WasteHandlingSimulation.SumTable.AddExpense",
                                "Add expense"
                              )
                            }}
                            <feather-icon icon="PlusIcon" /> </b-button
                        ></b-th>
                        <b-th>
                          {{
                            T("Web.Generic.Simulations.BaseLine", "Baseline")
                          }}</b-th
                        >
                        <b-th>
                          {{
                            T(
                              "Web.Generic.Simulations.Simulation",
                              "Simulation"
                            )
                          }}</b-th
                        >
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td>
                          {{
                            T(
                              "Web.SimluationsPage.WasteHandlingSimulation.SumTable.Logistics",
                              "Logistics (rent + empty)"
                            )
                          }}</b-td
                        >
                        <b-td>{{
                          baselineRentAndCollectionTotal | toCurrency
                        }}</b-td>
                        <b-td>{{
                          simulatedRentAndCollectionTotal | toCurrency
                        }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>{{
                          T("Web.Generic.PostingCategories.Charge")
                        }}</b-td>
                        <b-td>{{ baselineChargeTotal | toCurrency }}</b-td>
                        <b-td>{{ simulatedChargeTotal | toCurrency }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>{{
                          T("Web.Generic.PostingCategories.Income")
                        }}</b-td>
                        <b-td>-{{ baselineIncomeTotal | toCurrency }}</b-td>
                        <b-td>-{{ simulatedIncomeTotal | toCurrency }}</b-td>
                      </b-tr>
                      <b-tr
                        class="customExpenses"
                        v-for="item in localSimulation.customExpenses"
                        :key="item.id"
                      >
                        <b-td>
                          <div>
                            <b-form-select
                              class="customExpensesOption"
                              @change="executeGetCustomExpenseAsync(true)"
                              v-model="item.title"
                              :options="customExpensesOptions"
                              size="sm"
                            ></b-form-select>
                          </div>
                        </b-td>
                        <b-td>{{ item.baseline | toCurrency }}</b-td>
                        <b-td>
                          <div
                            class="w-100 d-flex position-relative justify-content-between"
                          >
                            <tailing-zero-number-input
                              @change="exeutePatchWithNoGet"
                              v-model="item.value"
                            />
                            <feather-icon
                              icon="XIcon"
                              class="remove-icon text-danger"
                              @click="removeCustomExpenses(item.id)"
                            />
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr class="row-bold totalCostRow">
                        <b-td>{{ T("Web.Generic.TotalCost") }}</b-td>
                        <b-td>{{ baselineTotalCost | toCurrency }}</b-td>
                        <b-td>{{ simulatedTotalCost | toCurrency }}</b-td>
                      </b-tr>
                      <b-tr class="row-bold">
                        <b-td>{{ T("Web.Generic.TotalCostPrTonnage") }}</b-td>
                        <b-td>{{ baselineTotalCostPrTons | toCurrency }}</b-td>
                        <b-td>{{ simulatedTotalCostPrTons | toCurrency }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>
            </b-card>
          </div>
          <div class="bottom-content">
            <infocards-selector-modal
              ref="InfocardSelectorModal"
              @infocards-selected="handleInfocardSelectorValueChanged"
            />
            <b-card no-body>
              <div class="p-2 d-flex justify-content-between">
                <div class="w-50">
                  <simulation-title-edit
                    v-model="localSimulation.title"
                    @change="exeutePatchWithNoGet"
                  />
                </div>
                <div class="d-flex justify-content-end w-50 align-items-center">
                  <b-button
                    class="add-btn"
                    variant="secondary"
                    size="sm"
                    @click="openInfocardSelectorModal"
                  >
                    {{
                      T(
                        "Web.SimluationsPage.WasteHandlingSimulation.AddInfocardToSimulation"
                      )
                    }}
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <loader class="loader ml-1" ref="loader" />
                </div>
              </div>
              <div class="table-container">
                <b-table-simple small v-if="showTable">
                  <b-thead>
                    <b-th colspan="2">
                      {{
                        T("Web.Generic.Infocards.InfocardName", "Infocard")
                      }}</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.RentPrMonth",
                          "Rent / month"
                        )
                      }}
                      [{{ currencyScope }}]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.IncomePrTons",
                          "Income / tonnage"
                        )
                      }}
                      [{{ currencyScope }}]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.ChargePrTons",
                          "Charge / tonnage"
                        )
                      }}
                      [{{ currencyScope }}]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.PricePrCollection",
                          "Price / collection"
                        )
                      }}
                      [{{ currencyScope }}]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.CollectedPastYear",
                          "Collected past year"
                        )
                      }}
                      [{{ T("Web.Generic.Units.Tonnage") }}]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.Collections",
                          "Collections"
                        )
                      }}
                      [#]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.TonsPrCollection",
                          "Tons / collection"
                        )
                      }}
                      [{{ T("Web.Generic.Units.Tonnage") }}]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.RentTotal",
                          "Rent total"
                        )
                      }}
                      [{{ currencyScope }}]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.IncomeTotal",
                          "Income total"
                        )
                      }}
                      [{{ currencyScope }}]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.ChargeTotal",
                          "Charge total"
                        )
                      }}
                      [{{ currencyScope }}]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.CollectionCostTotal",
                          "Collection cost total"
                        )
                      }}
                      [{{ currencyScope }}]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.TotalCost",
                          "Total cost"
                        )
                      }}
                      [{{ currencyScope }}]</b-th
                    >
                    <b-th
                      >{{
                        T(
                          "Web.SimluationsPage.WasteHandlingSimulation.SimulationTable.TotalCostPrTons",
                          "Total cost / tons"
                        )
                      }}
                      [{{ currencyScope }}]</b-th
                    >
                  </b-thead>
                  <b-tbody>
                    <template
                      v-for="(value, key) in simulationItemsGroupedByLocation"
                    >
                      <b-tr :key="key">
                        <b-td :colspan="15" variant="primary">
                          <small class="font-weight-bold">{{
                            getLocationNameFromId(key)
                          }}</small>
                        </b-td>
                      </b-tr>
                      <simulation-item-table-row
                        v-for="item in value"
                        :key="item.id"
                        :item="item"
                        :posting-categories-included="
                          localSimulation.postingCategoriesIncluded
                        "
                        @simulation-item-change="exeuteSimulationItemUpdate"
                        @simulation-item-removed="executeRemoveSimulationItem"
                        @simulation-alternativ-item-remove="
                          executeRemoveAlternativItem
                        "
                      />
                    </template>
                  </b-tbody>
                </b-table-simple>
                <div
                  v-if="localSimulation.simulationItems.length == 0"
                  class="d-flex justify-content-center m-3"
                >
                  <h4 class="text-muted">
                    {{
                      T(
                        "Web.SimluationsPage.WasteHandlingSimulation.NoOnfocardSelected",
                        "No infocard selected"
                      )
                    }}
                  </h4>
                </div>
              </div>
            </b-card>
          </div>
        </template>
      </div>
    </b-overlay>
  </page-loader>
</template>

<script>
import {
  BFormInput,
  BCard,
  BButton,
  BOverlay,
  BTableSimple,
  BFormSelect,
  BFormSelectOption,
  BThead,
  BTbody,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";
import TailingZeroNumberInput from "@/app/components/layout/TailingZeroNumberInput.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import InfocardsSelectorModal from "./InfocardsSelectorModal.vue";
import { mapActions, mapGetters } from "vuex";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import SimulationItemTableRow from "../components/SimulationItemTableRow.vue";
import loader from "@/components/layout/loader.vue";
import WasteHandlingSiumlationGraph from "../components/WasteHandlingSiumlationGraph.vue";
import SimulationTitleEdit from "../components/SimulationTitleEdit.vue";
import { uuid } from "vue-uuid";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: {
    Treeselect,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BOverlay,
    BTableSimple,
    BCard,
    BThead,
    BTh,
    BTbody,
    BTr,
    BTd,
    TailingZeroNumberInput,
    InfocardsSelectorModal,
    PageLoader,
    SimulationItemTableRow,
    loader,
    WasteHandlingSiumlationGraph,
    SimulationTitleEdit,
    DateRangePicker,
  },
  data() {
    return {
      rentToggleOption: "",
      incomeToggleOption: "",
      chargeToggleOption: "",
      priceToggleOption: "",
      wrapHeight: 0,
      fetching: false,
      loading: false,
      titleEdit: false,
      showTable: true,
      tableFields: [
        {
          key: "infocardName",
          label: "Container",
          sortable: true,
        },
      ],
      baselineSource: "LastInvoiced",
      dateRange: {
        startDate: moment(new Date()).subtract("year", 1),
        endDate: moment(new Date()),
      },
    };
  },
  async created() {
    await this.fetchSimulation({
      id: this.simulationId,
      startDate: this.dateRange.startDate.format("YYYY-MM-DD"),
      endDate: this.dateRange.endDate.format("YYYY-MM-DD"),
    });
    await this.executeGetCustomExpenseAsync();
  },
  mounted() {
    this.updateVhHeight();
    window.addEventListener("resize", () => this.updateVhHeight());
  },
  computed: {
    ...mapGetters({
      simulationById: "economics/simulations/wasteHandlingSimulations/byId",
      locations: "locations/list",
    }),

    customExpensesOptions() {
      let options = [
        {
          value: null,
          text: this.T("Web.Generic.Choose"),
        },
        {
          value: "GoodsPurchaseCost",
          text: this.T("Web.Generic.PostingCategories.GoodsPurchase"),
        },
        {
          value: "RepairPurchaseCost",
          text: this.T("Web.Generic.PostingCategories.RepairPurchase"),
        },
        {
          value: "SuppliesPurchaseCost",
          text: this.T("Web.Generic.PostingCategories.SuppliesPurchase"),
        },
        {
          value: "ExchangeCost",
          text: this.T("Web.Generic.PostingCategories.Exchange"),
        },
        {
          value: "RegistrationFeeCost",
          text: this.T("Web.Generic.PostingCategories.RegistrationFee"),
        },
        {
          value: "OilSurchargeFeeCost",
          text: this.T("Web.Generic.PostingCategories.OilSurchargeFee"),
        },
        {
          value: "WrongSortingCost",
          text: this.T("Web.Generic.PostingCategories.WrongSorting"),
        },
        {
          value: "FutileDrivingCost",
          text: this.T("Web.Generic.PostingCategories.FutileDriving"),
        },
        {
          value: "DeliveryAndRepatriationCost",
          text: this.T("Web.Generic.PostingCategories.DeliveryOrRepatriation"),
        },
      ];

      return options;
    },
    simulationId() {
      return this.$route.params.id;
    },
    localSimulation() {
      return this.simulationById(this.simulationId);
    },
    simulationItemsGroupedByLocation() {
      if (this.localSimulation.simulationItems.length == 0) return [];

      return this.localSimulation.simulationItems.reduce((pre, cur) => {
        (pre[cur.infocard.locationId] =
          pre[cur.infocard.locationId] || []).push(cur);
        return pre;
      }, {});
    },
    simulatedOptions() {
      let options = [{ value: "MANUAL_INPUT", text: "Manual input" }];
      let alternativesItem = [];

      this.localSimulation.simulationItems.forEach((simulationItem) => {
        simulationItem.alternatives.forEach((element) => {
          let found = alternativesItem.find((a) => a.text == element.title);
          if (found == undefined) {
            alternativesItem.push({ value: element.id, text: element.title });
          }
        });
      });

      if (alternativesItem.length > 0) {
        options.push({ value: "CHEAPEST", text: this.T("Web.SimluationsPage.WasteHandlingSimulation.SumTable.CheapestInput") });
        options.push({ value: "HIGHEST", text: this.T("Web.SimluationsPage.WasteHandlingSimulation.SumTable.HighestInput") });
        options.push({ text: "----", disabled: true });
        options = options.concat(alternativesItem);
      }

      return options;
    },
    /*
    simulationPropertiesOptions() {
      return [
        { id: "Rent", label: this.$t("Generic.Rent") },
        { id: "Charge", label: this.$t("Generic.Charge") },
        { id: "WasteCollection", label: this.$t("Generic.WasteCollection") },
        { id: "Income", label: this.$t("Generic.Income") },
        { id: "WrongSorting", label: this.$t("Generic.WrongSorting") },
        { id: "FutileDriving", label: this.$t("Generic.FutileDriving") },
        { id: "GoodsPurchase", label: this.$t("Generic.GoodsPurchase") },
        { id: "RepairPurchase", label: this.$t("Generic.RepairPurchase") },
        { id: "SuppliesPurchase", label: this.$t("Generic.SuppliesPurchase") },
        {
          id: "DeliveryOrRepatriation",
          label: this.$t("Generic.DeliveryOrRepatriation"),
        },
        { id: "Exchange", label: this.$t("Generic.Exchange") },
        { id: "RegistrationFee", label: this.$t("Generic.RegistrationFee") },
        { id: "OilSurchargeFee", label: this.$t("Generic.OilSurchargeFee") },
        { id: "OtherFees", label: this.$t("Generic.OtherFees") },
      ];
    },
    */
    totalSimulatedCustomExpenses() {
      return this.localSimulation.customExpenses.reduce(
        (total, c) => total + c.value,
        0
      );
    },

    totalBaselineCustomExpenses() {
      return this.localSimulation.customExpenses.reduce(
        (total, c) => total + c.baseline,
        0
      );
    },
    /*baseline*/
    baselineRentAndCollectionTotal() {
      let monthlyRentTotal = this.calculateSum("baseLine", "monthlyRent") * 12;
      let collectionCostTotal = this.localSimulation.simulationItems.reduce(
        (total, s) =>
          total + s.baseLine.collectionCost * s.baseLine.noOfCollections,
        0
      );

      return monthlyRentTotal + collectionCostTotal;
    },
    baselineChargeTotal() {
      let result = this.localSimulation.simulationItems.reduce(
        (total, s) => total + s.baseLine.chargePrTon * s.baseLine.tonsCollected,
        0
      );

      return result;
    },
    baselineTotalCost() {
      let result = this.localSimulation.simulationItems.reduce(
        (total, s) => total + this.calculateTotal(s, "baseLine"),
        0
      );

      return result + this.totalBaselineCustomExpenses;
    },
    baselineTotalTons() {
      return this.localSimulation.simulationItems.reduce(
        (total, s) => total + s.baseLine.tonsCollected,
        0
      );
    },

    baselineTotalCostPrTons() {
      if (this.baselineTotalTons <= 0) return 0;
      return this.baselineTotalCost / this.baselineTotalTons;
    },
    baselineIncomeTotal() {
      let result = this.localSimulation.simulationItems.reduce(
        (total, s) => total + s.baseLine.incomePrTon * s.baseLine.tonsCollected,
        0
      );

      return result;
    },
    /*baseline end*/
    /*simulated*/
    simulatedRentAndCollectionTotal() {
      let monthlyRentTotal = this.calculateSum("simulated", "monthlyRent") * 12;
      let collectionCostTotal = this.localSimulation.simulationItems.reduce(
        (total, s) =>
          total + s.simulated.collectionCost * s.baseLine.noOfCollections,
        0
      );

      return monthlyRentTotal + collectionCostTotal;
    },
    simulatedChargeTotal() {
      let result = this.localSimulation.simulationItems.reduce(
        (total, s) =>
          total + s.simulated.chargePrTon * s.simulated.tonsCollected,
        0
      );

      return result;
    },
    simulatedTotalTons() {
      return this.localSimulation.simulationItems.reduce(
        (total, s) => total + s.simulated.tonsCollected,
        0
      );
    },
    simulatedTotalCost() {
      let result = this.localSimulation.simulationItems.reduce(
        (total, s) => total + this.calculateTotal(s, "simulated"),
        0
      );

      return result + this.totalSimulatedCustomExpenses;
    },
    simulatedTotalCostPrTons() {
      if (this.simulatedTotalTons <= 0) return 0;

      return this.simulatedTotalCost / this.simulatedTotalTons;
    },
    simulatedIncomeTotal() {
      let result = this.localSimulation.simulationItems.reduce(
        (total, s) =>
          total + s.simulated.incomePrTon * s.simulated.tonsCollected,
        0
      );

      return result;
    },
    /*simulated end*/
  },
  methods: {
    ...mapActions({
      fetchSimulation:
        "economics/simulations/wasteHandlingSimulations/fetchSingle1",
      addSimulationItem:
        "economics/simulations/wasteHandlingSimulations/addSimulationItem",
      removeSimulationItem:
        "economics/simulations/wasteHandlingSimulations/removeSimulationItem",
      updateSimulationItem:
        "economics/simulations/wasteHandlingSimulations/updateSimulationItem",
      patchWithNoGet:
        "economics/simulations/wasteHandlingSimulations/patchWithNoGet",
      removeSimulationAlternativItem:
        "economics/simulations/wasteHandlingSimulations/removeSimulationAlternativItem",
      getCustomExpenseAsync:
        "economics/simulations/wasteHandlingSimulations/getCustomExpenseAsync",
    }),
    async refreshSimulation() {
      this.loading = true;
      await this.fetchSimulation({
        id: this.simulationId,
        startDate: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
      });
      this.loading = false;
    },

    async removeCustomExpenses(id) {
      const itemToRemove = this.localSimulation.customExpenses.find(
        (i) => i.id == id
      );
      const indexToRemove =
        this.localSimulation.customExpenses.indexOf(itemToRemove);
      this.localSimulation.customExpenses.splice(indexToRemove, 1);
      await this.exeutePatchWithNoGet();
    },
    async addExpense() {
      this.localSimulation.customExpenses.push({
        id: uuid.v4(),
        title: null,
        value: 0,
        baseline: 0,
      });
      await this.exeutePatchWithNoGet();
    },
    async updateBaselineSource() {
      await this.updateSimulation();
      await this.executeGetCustomExpenseAsync(false);
    },
    async executeGetCustomExpenseAsync(shouldUpdate) {
      if (this.localSimulation.customExpenses.length <= 0) return;

      if (shouldUpdate) await this.exeutePatchWithNoGet();

      await this.getCustomExpenseAsync({
        simulationId: this.localSimulation.id,
      });
    },
    async toggleSimulationOptionForAll(simulationOption, value) {
      let altItem = this.foundAltById(value);

      this.localSimulation.simulationItems.forEach((simulationItem) => {
        let hasAlts = simulationItem.alternatives.length > 0;

        if (value == "MANUAL_INPUT") {
          simulationItem[simulationOption] = value;
        } else if ((value == "CHEAPEST" || value == "HIGHEST") && hasAlts) {
          simulationItem[simulationOption] = value;
        } else if (altItem != undefined) {
          let found = simulationItem.alternatives.find(
            (a) => a.text == altItem.text
          );
          if (found != undefined) {
            simulationItem[simulationOption] = found.id;
          }
        }
      });

      setTimeout(() => {
        this.rentToggleOption = "";
        this.incomeToggleOption = "";
        this.chargeToggleOption = "";
        this.priceToggleOption = "";
      }, 500);
    },
    calculateTotal(item, property) {
      let totalRent = item[property].monthlyRent * 12;
      let totalIncome =
        item[property].incomePrTon * item[property].tonsCollected;
      let totalCharge =
        item[property].chargePrTon * item[property].tonsCollected;
      let totalCollectionCost =
        item[property].collectionCost * item[property].noOfCollections;

      return totalRent - totalIncome + totalCharge + totalCollectionCost;
    },
    calculateSum(property, item) {
      let result = this.localSimulation.simulationItems.reduce(function (
        total,
        s
      ) {
        let value = s[property][item] == undefined ? 0 : s[property][item];
        return total + value;
      },
      0);
      return result;
    },
    foundAltById(id) {
      let result = undefined;
      this.localSimulation.simulationItems.forEach((simulationItem) => {
        let found = simulationItem.alternatives.find((a) => a.id == id);
        if (found != undefined) {
          result = found;
          return;
        }
      });

      return result;
    },
    async executeRemoveAlternativItem(data) {
      this.loading = true;
      await this.removeSimulationAlternativItem({
        simulationId: this.simulationId,
        simulationItemId: data.simulationItemId,
        id: data.alternativItemId,
      });
      this.loading = false;
    },
    categoryIncluded(categoryName) {
      return this.localSimulation.postingCategoriesIncluded.includes(
        categoryName
      );
    },
    updateVhHeight() {
      const positionFromTop =
        this.$refs.simulationWrap.getBoundingClientRect().top;
      this.wrapHeight = window.innerHeight - positionFromTop;
    },
    getLocationNameFromId(locationId) {
      return this.locations.find((l) => l.id == locationId)?.name;
    },
    openInfocardSelectorModal() {
      this.$refs.InfocardSelectorModal.open();
    },
    async handleInfocardSelectorValueChanged(infocardIds) {
      try {
        this.loading = true;
        for (const id of infocardIds) {
          await this.addSimulationItem({
            simulationId: this.simulationId,
            infocardId: id,
          });
        }
      } finally {
        await this.fetchSimulation({
          id: this.simulationId,
          startDate: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
        });
        await this.executeGetCustomExpenseAsync();
        this.loading = false;
      }
    },
    async updateSimulation() {
      try {
        this.loading = true;
        await this.patchWithNoGet({ data: this.localSimulation });
        await this.fetchSimulation({
          id: this.simulationId,
          startDate: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
        });
      } finally {
        this.loading = false;
      }
    },
    async exeutePatchWithNoGet() {
      this.$refs.loader.loading();
      await this.patchWithNoGet({ data: this.localSimulation });
      this.$refs.loader.loadCompelete();
    },
    async exeuteSimulationItemUpdate(item) {
      this.$refs.loader.loading();
      let obj = {
        collectionCost: item.simulated.collectionCost,
        monthlyRent: item.simulated.monthlyRent,
        tonsPrCollection: item.simulated.tonsPrCollection,
        incomePrTon: item.simulated.incomePrTon,
        chargePrTon: item.simulated.chargePrTon,
        chargeSimulationOption: item.chargeSimulationOption,
        collectionCostSimulationOption: item.collectionCostSimulationOption,
        noOfCollections: item.simulated.noOfCollections,
        incomeSimulationOption: item.incomeSimulationOption,
        rentSimulationOption: item.rentSimulationOption,
        alternatives: item.alternatives,
        tonsCollected: item.simulated.tonsCollected,
      };
      await this.updateSimulationItem({
        simulationId: this.simulationId,
        id: item.id,
        data: obj,
      });
      this.$refs.loader.loadCompelete();
    },
    async executeRemoveSimulationItem(item) {
      try {
        this.loading = true;
        await this.removeSimulationItem({
          simulationId: this.localSimulation.id,
          item: item,
        });
      } finally {
        await this.fetchSimulation({
          id: this.simulationId,
          startDate: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.customExpensesOption {
  &:focus {
    box-shadow: unset;
  }
  border: unset;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
}
.totalCostRow {
  td {
    border-top: 2px solid #b1b1b1;
  }
}
.row-bold {
  font-weight: bold;
}
.customExpenses {
  .remove-icon {
    margin-left: -5px;
  }
  .edit-icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 11px;
    width: 11px;
  }
  input {
    border: 0px;
    &:focus {
      outline: 0;
      background-color: #dcdddf;
    }
  }
}
.addExpenseBtn {
  padding: 0.286rem 1rem;
}
.toggle_headers {
  th {
    background-color: unset !important;
    border-top: unset !important;
  }
}
.sumTable {
  select {
    max-width: 160px;
  }
  td {
    border-right: unset;
    border-left: unset;
  }
}
.simulation-wrap {
  height: 100%;
  position: relative;

  .top-content {
    overflow: hidden;
    min-height: 40%;
    padding-bottom: 20px;

    .card {
      height: 100%;
      position: relative;
      margin-bottom: 0px;
    }
  }

  .bottom-content {
    height: 60%;
    position: relative;
    overflow: hidden;
    padding-bottom: 20px;

    .card {
      height: 100%;
      position: relative;
      margin-bottom: 0px;

      .add-btn {
        min-width: 140px;
        margin-left: 1rem;
      }

      .table-container {
        height: 100%;
        overflow-y: auto;

        th {
          position: sticky;
          top: -1px;
          z-index: 2;
        }
      }
    }
  }
}
</style>