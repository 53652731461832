<template>
  <div style="display: contents" class="sim-tr">
    <confirm-modal ref="confirmModal" />
    <!-- Baseline -->
    <b-tr>
      <b-th class="position-relative" :rowspan="totalRowSpan" width="150">
        <div class="d-flex flex-column align-items-center">
          <small>{{ item.infocard.refId }}</small>
          <span class="font-weight-bold">{{ item.infocard.name }}</span>
          <small
            >{{ item.infocard.wasteFractionName }}
            <span v-if="item.infocard.sizeUnit != 'Unknown'"
              >{{ item.infocard.size | toCurrency }}
              {{ getContainerSizeUnit(item.infocard.sizeUnit).text }}</span
            ></small
          >
        </div>
        <feather-icon
          icon="XIcon"
          class="remove-icon text-danger float-right"
          @click="removeSimulationItem"
        />
      </b-th>
      <b-th class="text-right" rowspan="2">
        {{ T("Web.Generic.Simulations.BaseLine", "Baseline") }}
      </b-th>
      <b-td colspan="4" class="text-center p-0">
        <small class="font-weight-bold"
          ><feather-icon icon="TruckIcon" size="12" />
          {{ item.infocard.collectorName }}</small
        >
      </b-td>
      <b-td rowspan="2" variant="light" class="text-center">
        {{ item.baseLine.tonsCollected | toCurrency }}</b-td
      >
      <b-td rowspan="2" variant="light" class="text-center">
        <small>{{ item.baseLine.noOfCollections | toCurrency }}</small></b-td
      >
      <b-td rowspan="2" class="text-center" variant="light"
        ><small>{{ item.baseLine.tonsPrCollection | toCurrency }}</small></b-td
      >
      <b-td rowspan="2" class="text-center" variant="light"
        ><small>{{ totalRent | toCurrency }}</small></b-td
      >
      <b-td rowspan="2" class="text-center" variant="light"
        ><small>-{{ totalIncome | toCurrency }}</small></b-td
      >
      <b-td rowspan="2" class="text-center" variant="light"
        ><small>{{ totalCharge | toCurrency }}</small></b-td
      >
      <b-td rowspan="2" class="text-center" variant="light"
        ><small>{{ totalCollectionCost | toCurrency }}</small></b-td
      >
      <b-td
        rowspan="2"
        variant="secondary"
        class="text-center font-weight-bold"
      >
        <small> {{ totalCost | toCurrency }}</small>
      </b-td>
      <b-td
        rowspan="2"
        variant="secondary"
        class="text-center font-weight-bold"
      >
        <small>{{ totalCostPrTons | toCurrency }}</small>
      </b-td>
    </b-tr>
    <b-tr>
      <b-td class="text-center" variant="light"
        ><small>{{ item.baseLine.monthlyRent | toCurrency }}</small></b-td
      >
      <b-td class="text-center" variant="light"
        ><small>{{ item.baseLine.incomePrTon | toCurrency }}</small></b-td
      >
      <b-td class="text-center" variant="light"
        ><small>{{ item.baseLine.chargePrTon | toCurrency }}</small></b-td
      >
      <b-td class="text-center" variant="light"
        ><small>{{ item.baseLine.collectionCost | toCurrency }}</small></b-td
      >
    </b-tr>
    <!-- Baseline end -->
    <!-- Simulated -->
    <b-tr>
      <b-th class="text-right" rowspan="2">
        <div class="d-flex flex-column">
          <b-form-select
            @change="toggleSimulationOptionForAll"
            v-model="simulationOptionForAll"
            class="mb-1"
            :options="simulatedOptions"
            size="sm"
          >
            <template #first>
              <b-form-select-option :value="''" disabled>{{
                T("Web.Generic.Toggle")
              }}</b-form-select-option>
            </template>
          </b-form-select>
          <div>
            {{ T("Web.Generic.Simulations.Simulated", "Simulated") }}
          </div>
        </div>
      </b-th>
      <b-td colspan="1" variant="light" class="text-center p-0">
        <b-form-select
          @change="
            setSimulationValueByProperty(
              'monthlyRent',
              item.rentSimulationOption
            )
          "
          v-model="item.rentSimulationOption"
          :options="simulatedOptions"
          size="sm"
        ></b-form-select>
      </b-td>
      <b-td colspan="1" variant="light" class="text-center p-0">
        <b-form-select
          @change="
            setSimulationValueByProperty(
              'incomePrTon',
              item.incomeSimulationOption
            )
          "
          v-model="item.incomeSimulationOption"
          :options="simulatedOptions"
          size="sm"
        ></b-form-select>
      </b-td>
      <b-td colspan="1" variant="light" class="text-center p-0">
        <b-form-select
          @change="
            setSimulationValueByProperty(
              'chargePrTon',
              item.chargeSimulationOption
            )
          "
          v-model="item.chargeSimulationOption"
          :options="simulatedOptions"
          size="sm"
        ></b-form-select>
      </b-td>
      <b-td colspan="1" variant="light" class="text-center p-0">
        <b-form-select
          @change="
            setSimulationValueByProperty(
              'collectionCost',
              item.collectionCostSimulationOption
            )
          "
          v-model="item.collectionCostSimulationOption"
          :options="simulatedOptions"
          size="sm"
        ></b-form-select>
      </b-td>
      <b-td rowspan="2" variant="light" class="text-center">
        <tailing-zero-number-input
          @change="updateSimulationItem"
          class="w-100"
          v-model="item.simulated.tonsCollected"
      /></b-td>
      <b-td class="text-center text-black" rowspan="2">
        <tailing-zero-number-input
          @change="updatetonsPrCollection"
          class="w-100"
          v-model="item.simulated.noOfCollections"
        />
      </b-td>
      <b-td rowspan="2" class="text-center">
        <tailing-zero-number-input
          @change="updateNoOfCollection"
          class="w-100"
          v-model="item.simulated.tonsPrCollection"
        />
      </b-td>
      <b-td rowspan="2" class="text-center">
        {{ totalRentSimulated | toCurrency }}</b-td
      >
      <b-td rowspan="2" class="text-center"
        >-{{ totalIncomeSimulated | toCurrency }}</b-td
      >
      <b-td rowspan="2" class="text-center">{{
        totalChargeSimulated | toCurrency
      }}</b-td>
      <b-td rowspan="2" class="text-center">{{
        totalCollectionCostSimulated | toCurrency
      }}</b-td>
      <b-td
        rowspan="2"
        variant="secondary"
        class="text-center font-weight-bold"
      >
        {{ totalCostSimulated | toCurrency }}
      </b-td>
      <b-td
        rowspan="2"
        variant="secondary"
        class="text-center font-weight-bold"
      >
        {{ totalCostPrTonsSimulated | toCurrency }}
      </b-td>
    </b-tr>
    <b-tr>
      <b-td class="text-center">
        <tailing-zero-number-input
          @change="updateSimulationItem"
          v-if="item.rentSimulationOption == 'MANUAL_INPUT'"
          class="w-100"
          v-model="item.simulated.monthlyRent"
        />
        <span v-else class="font-weight-bold">
          {{ item.simulated.monthlyRent | toCurrency }}
          <feather-icon
            icon="Link2Icon"
            v-b-tooltip.hover.top="selectedAlternativForRent.title"
          />
        </span>
      </b-td>
      <b-td class="text-center">
        <tailing-zero-number-input
          @change="updateSimulationItem"
          v-if="item.incomeSimulationOption == 'MANUAL_INPUT'"
          class="w-100"
          v-model="item.simulated.incomePrTon"
        />
        <span v-else class="font-weight-bold">
          {{ item.simulated.incomePrTon | toCurrency }}
          <feather-icon
            icon="Link2Icon"
            v-b-tooltip.hover.top="selectedAlternativForIncome.title"
          />
        </span>
      </b-td>
      <b-td class="text-center">
        <tailing-zero-number-input
          @change="updateSimulationItem"
          v-if="item.chargeSimulationOption == 'MANUAL_INPUT'"
          class="w-100"
          v-model="item.simulated.chargePrTon"
        />
        <span v-else class="font-weight-bold">
          {{ item.simulated.chargePrTon | toCurrency }}
          <feather-icon
            icon="Link2Icon"
            v-b-tooltip.hover.top="selectedAlternativForCharge.title"
          />
        </span>
      </b-td>
      <b-td class="text-center">
        <tailing-zero-number-input
          @change="updateSimulationItem"
          v-if="item.collectionCostSimulationOption == 'MANUAL_INPUT'"
          class="w-100"
          v-model="item.simulated.collectionCost"
        />
        <span v-else class="font-weight-bold">
          {{ item.simulated.collectionCost | toCurrency }}
          <feather-icon
            icon="Link2Icon"
            v-b-tooltip.hover.top="selectedAlternativForCollectionCost.title"
          />
        </span>
      </b-td>
    </b-tr>
    <!-- Simulated end -->
    <!-- Alternatives -->
    <alternative-table-row
      @item-change="updateAlternativItem"
      @item-remove="removeAlternativItem"
      v-for="(alternative, index) in item.alternatives"
      :item="alternative"
      :baseLine="item.baseLine"
      :key="index"
    />

    <b-tr>
      <b-td colspan="14" class="add-action-row text-center">
        <small>
          <a @click.prevent="addAlternative" href="#"
            >{{
              T(
                "Web.SimluationsPage.WasteHandlingSimulation.AddAlternative",
                "Add alternative"
              )
            }}
            <feather-icon icon="PlusIcon" class="plus-icon" />
          </a>
        </small>
      </b-td>
    </b-tr>

    <!-- Alternatives end -->
  </div>
</template>
<script>
import {
  BTr,
  BTh,
  BTd,
  BButton,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import TailingZeroNumberInput from "@/app/components/layout/TailingZeroNumberInput.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import AlternativeTableRow from "./AlternativeTableRow.vue";
import { uuid } from "vue-uuid";
import { VBTooltip } from "bootstrap-vue";

export default {
  props: {
    item: {
      required: true,
    },
  },
  components: {
    BTr,
    BTh,
    BTd,
    TailingZeroNumberInput,
    ConfirmModal,
    BButton,
    AlternativeTableRow,
    BFormSelect,
    BFormSelectOption,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {
    if (this.item.simulated.noOfCollections == 0)
      this.item.simulated.noOfCollections = this.item.baseLine.noOfCollections;
    if (this.item.simulated.tonsPrCollection == 0)
      this.item.simulated.tonsPrCollection =
        this.item.baseLine.tonsPrCollection;
  },
  data() {
    return {
      simulationOptionForAll: "",
    };
  },
  computed: {
    selectedAlternativForCollectionCost() {
      let option = this.getSimulationByOption(
        this.collectionCostSimulationOption,
        "collectionCost"
      );

      return option;
    },
    selectedAlternativForCharge() {
      let option = this.getSimulationByOption(
        this.chargeSimulationOption,
        "chargePrTon"
      );

      return option;
    },
    selectedAlternativForIncome() {
      let option = this.getSimulationByOption(
        this.incomeSimulationOption,
        "incomePrTon"
      );

      return option;
    },
    selectedAlternativForRent() {
      let option = this.getSimulationByOption(
        this.rentSimulationOption,
        "monthlyRent"
      );
      return option;
    },

    simulatedOptions() {
      let options = [
        {
          value: "MANUAL_INPUT",
          text: this.T(
            "Web.SimluationsPage.WasteHandlingSimulation.SumTable.ManualInput",
            "Manual input"
          ),
        },
      ];
      if (this.item.alternatives.length == 0) return options;
      options.push({
        value: "CHEAPEST",
        text: this.T(
          "Web.SimluationsPage.WasteHandlingSimulation.SumTable.CheapestInput",
          "Cheapest"
        ),
      });
      options.push({
        value: "HIGHEST",
        text: this.T(
          "Web.SimluationsPage.WasteHandlingSimulation.SumTable.HighestInput",
          "Highest"
        ),
      });
      options.push({ text: "----", disabled: true });
      this.item.alternatives.forEach((item) => {
        options.push({ value: item.id, text: item.title });
      });
      return options;
    },
    /** <-- Baseline --> */
    totalRowSpan() {
      return 5 + this.item.alternatives.length * 2;
    },
    totalRent() {
      return this.item.baseLine.monthlyRent * 12;
    },
    totalIncome() {
      return this.item.baseLine.incomePrTon * this.item.baseLine.tonsCollected;
    },
    totalCharge() {
      let result =
        this.item.baseLine.chargePrTon * this.item.baseLine.tonsCollected;

      return result;
    },
    totalCollectionCost() {
      return (
        this.item.baseLine.collectionCost * this.item.baseLine.noOfCollections
      );
    },
    totalCost() {
      let result =
        this.totalRent -
        this.totalIncome +
        this.totalCharge +
        this.totalCollectionCost;

      return result;
    },
    totalCostPrTons() {
      let result = 0;

      if (this.item.baseLine.tonsCollected > 0)
        result = this.totalCost / this.item.baseLine.tonsCollected;

      return result;
    },
    /** <-- Baseline end --> */
    /** <-- Simulation --> */
    totalRentSimulated() {
      return this.item.simulated.monthlyRent * 12;
    },
    totalIncomeSimulated() {
      return (
        this.item.simulated.incomePrTon * this.item.simulated.tonsCollected
      );
    },
    totalChargeSimulated() {
      return (
        this.item.simulated.chargePrTon * this.item.simulated.tonsCollected
      );
    },
    totalCollectionCostSimulated() {
      return (
        this.item.simulated.collectionCost * this.item.simulated.noOfCollections
      );
    },
    totalCostSimulated() {
      return (
        this.totalRentSimulated -
        this.totalIncomeSimulated +
        this.totalChargeSimulated +
        this.totalCollectionCostSimulated
      );
    },
    totalCostPrTonsSimulated() {
      if (this.item.simulated.tonsCollected > 0)
        return this.totalCostSimulated / this.item.simulated.tonsCollected;
      return 0;
    },
    /** <-- Simulation end --> */
    rentSimulationOption() {
      return this.item.rentSimulationOption;
    },
    incomeSimulationOption() {
      return this.item.incomeSimulationOption;
    },
    chargeSimulationOption() {
      return this.item.chargeSimulationOption;
    },
    collectionCostSimulationOption() {
      return this.item.collectionCostSimulationOption;
    },
  },
  methods: {
    toggleSimulationOptionForAll() {
      let property;

      if (
        this.simulationOptionForAll == "CHEAPEST" ||
        this.simulationOptionForAll == "HIGHEST"
      ) {
        property = "totalCost";
      }

      let selectedSimulation = this.getSimulationByOption(
        this.simulationOptionForAll,
        property
      );

      let selectedOption =
        selectedSimulation.id == undefined
          ? this.simulationOptionForAll
          : selectedSimulation.id;

      this.item.rentSimulationOption = selectedOption;
      this.item.incomeSimulationOption = selectedOption;
      this.item.chargeSimulationOption = selectedOption;
      this.item.collectionCostSimulationOption = selectedOption;

      for (var propertyName in selectedSimulation) {
        this.item.simulated[propertyName] = selectedSimulation[propertyName];
      }

      this.updateSimulationItem();
    },
    setSimulationValueByProperty(property, simulationOption) {
      let simulation = this.getSimulationByOption(simulationOption, property);

      this.item.simulated[property] = simulation[property];
      this.updateSimulationItem();

      return simulation;
    },
    getSimulationByOption(simulationOption, property) {
      if (simulationOption == "MANUAL_INPUT") return this.item.baseLine;

      let simulation = this.item.simulated;

      if (simulationOption == "CHEAPEST" && property != undefined)
        return this.cheapestAlternativeForProperty(property);

      if (simulationOption == "HIGHEST" && property != undefined)
        return this.highestAlternativeForPropertyAlternativeForProperty(
          property
        );

      let alternative = this.item.alternatives.find(
        (a) => a.id == simulationOption
      );

      if (alternative != null) simulation = alternative;

      return simulation;
    },
    addAlternative() {
      const alternativeTitle = `Alternative #${
        this.item.alternatives.length + 1
      }`;
      this.item.alternatives.push({
        id: uuid.v4(),
        title: alternativeTitle,
        monthlyRent: 0,
        incomePrTon: 0,
        chargePrTon: 0,
        collectionCost: 0,
        tonsPrCollection: this.item.simulated.tonsPrCollection,
        tonsCollected: this.item.simulated.tonsCollected,
        noOfCollections: this.item.simulated.noOfCollections,
      });

      this.updateSimulationItem();
    },
    async removeSimulationItem() {
      if (!(await this.$refs.confirmModal.confirm())) return;
      this.$emit("simulation-item-removed", this.item);
    },
    updateAlternativItem(item) {
      if (this.selectedAlternativForRent.id == item.id)
        this.item.simulated.monthlyRent = item.monthlyRent;

      if (this.selectedAlternativForIncome.id == item.id)
        this.item.simulated.incomePrTon = item.incomePrTon;

      if (this.selectedAlternativForCharge.id == item.id)
        this.item.simulated.chargePrTon = item.chargePrTon;

      if (this.selectedAlternativForCollectionCost.id == item.id)
        this.item.simulated.collectionCost = item.collectionCost;

      this.updateSimulationItem();
    },
    async removeAlternativItem(item) {
      if (!(await this.$refs.confirmModal.confirm())) return;
      if (this.selectedAlternativForRent.id == item.id)
        this.item.rentSimulationOption = "MANUAL_INPUT";

      if (this.selectedAlternativForIncome.id == item.id)
        this.item.incomeSimulationOption = "MANUAL_INPUT";

      if (this.selectedAlternativForCharge.id == item.id)
        this.item.chargeSimulationOption = "MANUAL_INPUT";

      if (this.selectedAlternativForCollectionCost.id == item.id)
        this.item.collectionCostSimulationOption = "MANUAL_INPUT";

      this.$emit("simulation-alternativ-item-remove", {
        simulationItemId: this.item.id,
        alternativItemId: item.id,
      });
    },
    updatetonsPrCollection() {
      let result = 0;
      if (this.item.simulated.noOfCollections > 0)
        result =
          this.item.simulated.tonsCollected /
          this.item.simulated.noOfCollections;

      this.item.simulated.tonsPrCollection = result;
      this.updateSimulationItem();
    },
    updateNoOfCollection() {
      let result =
        this.item.simulated.tonsPrCollection > 0
          ? this.item.simulated.tonsCollected /
            this.item.simulated.tonsPrCollection
          : 0;

      this.item.simulated.noOfCollections = result;
      this.updateSimulationItem();
    },
    updateSimulationItem() {
      this.$emit("simulation-item-change", this.item);
    },
    cheapestAlternativeForProperty(property) {
      let cheapestAlterantive = null;
      this.item.alternatives.forEach((alternative) => {
        if (
          cheapestAlterantive == null ||
          alternative[property] < cheapestAlterantive[property]
        )
          cheapestAlterantive = alternative;
      });
      return cheapestAlterantive;
    },
    highestAlternativeForPropertyAlternativeForProperty(property) {
      let cheapestAlterantive = null;
      this.item.alternatives.forEach((alternative) => {
        if (
          cheapestAlterantive == null ||
          alternative[property] > cheapestAlterantive[property]
        )
          cheapestAlterantive = alternative;
      });
      return cheapestAlterantive;
    },
  },
  watch: {
    totalCost() {
      this.item.baseLine["totalCost"] = this.totalCost;
    },
    totalCharge() {
      this.item.baseLine["chargeTotal"] = this.totalCharge;
    },
    collectionCostSimulationOption() {
      let result = this.getSimulationByOption(
        this.collectionCostSimulationOption,
        "collectionCost"
      );
      this.item.simulated["collectionCost"] = result["collectionCost"];
      this.updateSimulationItem();
    },
    chargeSimulationOption() {
      let result = this.getSimulationByOption(
        this.chargeSimulationOption,
        "chargePrTon"
      );
      this.item.simulated["chargePrTon"] = result["chargePrTon"];
      this.updateSimulationItem();
    },
    incomeSimulationOption() {
      let result = this.getSimulationByOption(
        this.incomeSimulationOption,
        "incomePrTon"
      );
      this.item.simulated["incomePrTon"] = result["incomePrTon"];
      this.updateSimulationItem();
    },
    rentSimulationOption() {
      let result = this.getSimulationByOption(
        this.rentSimulationOption,
        "monthlyRent"
      );
      this.item.simulated["monthlyRent"] = result["monthlyRent"];
      this.updateSimulationItem();
    },
  },
};
</script>
<style lang="scss" scoped>
.row-actions {
  width: 20px;
  margin-left: 1rem;
  .add-action {
    font-size: 8px;
  }
}

.add-action-row {
  background-color: #f3f2f7;
  a {
    color: #6e6b7b;
    font-weight: bold;
  }
}

.sim-tr {
  input {
    border: 0px;
    font-weight: bold;
    text-align: center;
    &:focus {
      outline: 0;
      background-color: #dcdddf;
    }
  }

  th,
  td {
    position: relative;
  }

  .remove-icon {
    position: absolute;
    top: 10px;
    width: 20px;
    height: 20px;
    &:hover {
      cursor: pointer;
    }
  }
}
.text-black {
  color: #000;
}
</style>