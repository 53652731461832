<template>
  <input
    type="number"
    :value="content"
    @blur="handleInput"
    @focus="$event.target.select()"
  />
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {};
  },
  computed: {
    content() {
      return this.value.toFixed(2);
    },
  },
  methods: {
    handleInput(e) {
      const newValue = parseFloat(e.target.value).toFixed(2);
      if (newValue == this.content) return;
      this.$emit("input", Number(newValue));
      this.$emit("change");
    },
  },
};
</script>