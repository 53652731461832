<template>
  <div
    v-if="simulationItems.length == 0"
    class="h-100 d-flex align-items-center justify-content-center"
  >
    <h1 class="text-muted m-3">
      {{T('Web.SimluationsPage.WasteHandlingSimulation.StartGuideText', 'Start by adding an infocard to simulate')}}
      <feather-icon icon="BarChart2Icon" size="35" />
    </h1>
  </div>
  <div class="px-2 pt-3 pb-1 bar-chart-container" v-else>
    <bar-chart ref="BarChart" :data="data" :options="options" />
  </div>
</template>

<script>
import BarChart from "@/app/common/charts/BarChart.vue";
import { $themeColors } from "@themeConfig";
import Vue from "vue";

const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

export default {
  components: { BarChart },
  props: {
    localSimulation: {
      required: true,
    },
  },

  computed: {
    labels() {
      let totalCostLabel = [
        ` ${this.T('Web.Generic.TotalCost', 'Total cost')} [${this.currencyScope}]`,
        ` ${this.T('Web.Generic.Difference', 'Difference')}: ${this.getTotalCostItemDiff()}`,
        ` ${this.T('Web.Generic.Difference', 'Difference')}: ${this.getTotalCostDiffInPercentage()} [%]`,
      ];

      let totalTonsCostLabel = [
        ` ${this.T('Web.Generic.TotalCostPrTonnage', 'Total cost pr tonnage')}[${this.currencyScope}]`,
        ` ${this.T('Web.Generic.Difference', 'Difference')}: ${this.getTotalTonsCostItemDiff()}`,
        ` ${this.T('Web.Generic.Difference', 'Difference')}: ${this.getTotalTonsCostDiffInPercentage()} [%]`,
      ];

      return [totalCostLabel, totalTonsCostLabel];
    },
    simulationItems() {
      return this.localSimulation.simulationItems;
    },
    totalCostPrTonsSimulated() {
      if (this.totalSimulatedTonsCollected <= 0) return 0;

      return this.totalSimulatedItemExpense / this.totalSimulatedTonsCollected;
    },
    totalSimulatedTonsCollected() {
      return this.calculateSum("simulated", "tonsCollected");
    },
    totalTonsCollected() {
      return this.calculateSum("baseLine", "tonsCollected");
    },
    totalCostPrTons() {
      if (this.totalTonsCollected <= 0) return 0;
      return this.totalBaselineItemExpense / this.totalTonsCollected;
    },
    totalBaselineItemExpense() {
      let result = 0;

      this.simulationItems.forEach((element) => {
        result += this.calcTotalBaselineItemExpense(element);
      });

      return result + this.totalBaselineCustomExpenses;
    },
    totalSimulatedItemExpense() {
      let result = 0;

      this.simulationItems.forEach((element) => {
        result += this.calcTotalSimulatedItemExpense(element);
      });

      return result + this.totalSimulatedCustomExpenses;
    },
    totalSimulatedCustomExpenses() {
      return this.localSimulation.customExpenses.reduce(
        (total, c) => total + c.value,
        0
      );
    },

    totalBaselineCustomExpenses() {
      return this.localSimulation.customExpenses.reduce(
        (total, c) => total + c.baseline,
        0
      );
    },
    data() {
      return {
        labels: this.labels,
        datasets: [
          {
            data: [
              this.totalBaselineItemExpense.toFixed(2),
              this.totalCostPrTons.toFixed(2),
            ],
            backgroundColor: "#82868b",
            borderColor: "transparent",
            label: "Baseline",
            maxBarThickness: 100,
          },
          {
            data: [
              this.totalSimulatedItemExpense.toFixed(2),
              this.totalCostPrTonsSimulated.toFixed(2),
            ],
            backgroundColor: "#407e3d",
            borderColor: "transparent",
            label: "Simulated",
            maxBarThickness: 100,
          },
        ],
      };
    },
  },

  methods: {
    calculateTotal(item, property) {
      let totalRent = item[property].monthlyRent * 12;
      let totalIncome =
        item[property].incomePrTon * item[property].tonsCollected;
      let totalCharge =
        item[property].chargePrTon * item[property].tonsCollected;
      let totalCollectionCost =
        item[property].collectionCost * item[property].noOfCollections;

      return totalRent - totalIncome + totalCharge + totalCollectionCost;
    },
    calculateSum(property, item) {
      let result = this.localSimulation.simulationItems.reduce(function (
        total,
        s
      ) {
        let value = s[property][item] == undefined ? 0 : s[property][item];
        return total + value;
      },
      0);
      return result;
    },
    calcTotalBaselineItemExpense(item) {
      let baseLineResult =
        item.baseLine.monthlyRent * 12 -
        item.baseLine.incomePrTon * item.baseLine.tonsCollected +
        item.baseLine.chargePrTon * item.baseLine.tonsCollected +
        item.baseLine.collectionCost * item.baseLine.noOfCollections;

      return baseLineResult;
    },
    calcTotalSimulatedItemExpense(item) {
      let simulatedResult =
        item.simulated.monthlyRent * 12 -
        item.simulated.incomePrTon * item.simulated.tonsCollected +
        item.simulated.chargePrTon * item.simulated.tonsCollected +
        item.simulated.collectionCost * item.simulated.noOfCollections;
      return simulatedResult;
    },
    getTotalCostItemDiff() {
      const simulatedTotal = this.totalSimulatedItemExpense;
      const baseLinetotal = this.totalBaselineItemExpense;

      return this.$options.filters.toCurrency(simulatedTotal - baseLinetotal);
    },
    getTotalTonsCostItemDiff() {
      const simulatedTotal = this.totalCostPrTonsSimulated;
      const baseLinetotal = this.totalCostPrTons;

      return this.$options.filters.toCurrency(simulatedTotal - baseLinetotal);
    },
    getTotalTonsCostDiffInPercentage() {
      const simulatedTotal = this.totalCostPrTonsSimulated;
      const baseLinetotal = this.totalCostPrTons;
      let diffInPercentage =
        ((simulatedTotal - baseLinetotal) / baseLinetotal) * 100;
      if (isNaN(diffInPercentage)) diffInPercentage = 0;
      return `${
        diffInPercentage < 0 ? "" : "+"
      }${this.$options.filters.toCurrency(diffInPercentage)}`;
    },
    getTotalCostDiffInPercentage() {
      const simulatedTotal = this.totalSimulatedItemExpense;
      const baseLinetotal = this.totalBaselineItemExpense;
      let diffInPercentage =
        ((simulatedTotal - baseLinetotal) / baseLinetotal) * 100;
      if (isNaN(diffInPercentage)) diffInPercentage = 0;
      return `${
        diffInPercentage < 0 ? "" : "+"
      }${this.$options.filters.toCurrency(diffInPercentage)}`;
    },
  },

  data() {
    return {
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: "bottom",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                min: 0,
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
    };
  },

  watch: {
    localSimulation: {
      handler(val) {
        const self = this;
        Vue.nextTick(() => {
          self.$refs.BarChart.updateChart();
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.bar-chart-container {
  position: relative;
  height: 100%;

  div {
    height: 100%;
  }
}
</style>