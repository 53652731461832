<template>
  <b-modal
    id="booststrapmodal"
    :title="
      T(
        'Web.SimluationsPage.WasteHandlingSimulation.AddInfocardToSimulation',
        'Add infocard to simulation'
      )
    "
    centered
    :visible="visible"
    @hidden="handleModalHidden"
    :no-close-on-backdrop="true"
    :hide-header-close="true"
    size="lg"
  >
    <page-loader :loading="loading">
      <div>
        <treeselect
          :multiple="true"
          :options="options"
          :disable-branch-nodes="true"
          v-model="selectedInfocardIds"
          :normalizer="(i) => i.id"
        />
      </div>
    </page-loader>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary" class="float-right" @click="handleSubmit">
          {{ T("Web.Generic.Add", "Add") }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleModalHidden"
        >
          {{ T("Web.Generic.Cancel", "Cancel") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BButton } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    BButton,
    BModal,
    PageLoader,
    Treeselect,
  },
  data() {
    return {
      visible: false,
      loading: false,
      selectedInfocardIds: [],
    };
  },
  computed: {
    ...mapGetters({ infocards: "infocards/list", locations: "locations/list" }),
    options() {
      return this.locations.map((l) => {
        return {
          id: l.id,
          label: l.name,
          children: this.infocards
            .filter(
              (i) => i.locationId == l.id && i.type == "ContainerInfoCard"
            )
            .map((i) => {
              return {
                id: i.id,
                label: `${i.refId} | ${i.name}`,
              };
            }),
        };
      });
    },
  },
  methods: {
    ...mapActions({ fetchInfocards: "infocards/fetchList" }),
    async open() {
      try {
        this.loading = true;
        await this.fetchInfocards();
        this.visible = true;
      } finally {
        this.loading = false;
      }
    },
    handleModalHidden() {
      this.visible = false;
      this.selectedInfocardIds = [];
    },
    handleSubmit() {
      this.$emit("infocards-selected", this.selectedInfocardIds);
      this.handleModalHidden();
    },
  },
};
</script>

<style lang="scss">
.vue-treeselect__multi-value-item {
  background: #cadbc9;
}

.vue-treeselect__multi-value-item,
.vue-treeselect__value-remove {
  color: #407e3d;
  font-weight: bold;
}

.vue-treeselect__checkbox--checked,
.vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked {
  border-color: #407e3d;
  background: #407e3d;
}

.vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked {
  border-color: #407e3d;
}
</style>