<template>
  <div style="display: contents" class="sim-tr">
    <b-tr>
      <b-th rowspan="2">
        <feather-icon
          icon="XIcon"
          class="remove-icon text-danger float-right"
          @click="removeItem"
        />
        <!--
        <button @click="removeItem" type="button" class="btn btn-danger btn-sm">
          {{ $t("Generic.Removebtn") }}
        </button>
        -->
      </b-th>
      <b-td colspan="4" class="title p-0">
        <b-form-input @blur="updatedItem" size="sm" :value="item.title" />
        <feather-icon icon="Edit2Icon" class="edit-icon ml-1" />
      </b-td>
      <b-td rowspan="2" variant="light" class="text-center">
        <tailing-zero-number-input
          @change="updatedItem"
          class="w-100"
          v-model="item.tonsCollected"
      /></b-td>
      <b-td class="text-center text-black" rowspan="2">
        <tailing-zero-number-input
          @change="updatetonsPrCollection"
          class="w-100"
          v-model="item.noOfCollections"
        />
      </b-td>
      <b-td rowspan="2" class="text-center">
        <tailing-zero-number-input
          @change="updateNoOfCollection"
          class="w-100"
          v-model="item.tonsPrCollection"
        />
      </b-td>
      <b-td rowspan="2" class="text-center"
        ><small>{{ totalRent | toCurrency }}</small>
      </b-td>
      <b-td rowspan="2" class="text-center">
        <small> -{{ totalIncome | toCurrency }} </small>
      </b-td>
      <b-td rowspan="2" class="text-center">
        <small> {{ totalCharge | toCurrency }} </small>
      </b-td>
      <b-td rowspan="2" class="text-center"
        ><small>{{ totalCollectionCost | toCurrency }}</small></b-td
      >
      <b-td rowspan="2" class="text-center">
        <small class="font-weight-bold">{{ totalCost | toCurrency }}</small>
      </b-td>
      <b-td rowspan="2" class="text-center">
        <small class="font-weight-bold">{{
          totalCostPrTons | toCurrency
        }}</small>
      </b-td>
    </b-tr>
    <b-tr>
      <b-td>
        <tailing-zero-number-input
          @change="updatedItem"
          class="w-100"
          v-model="item.monthlyRent"
        />
      </b-td>
      <b-td>
        <tailing-zero-number-input
          @change="updatedItem"
          class="w-100"
          v-model="item.incomePrTon"
        />
      </b-td>
      <b-td>
        <tailing-zero-number-input
          @change="updatedItem"
          class="w-100"
          v-model="item.chargePrTon"
        />
      </b-td>
      <b-td>
        <tailing-zero-number-input
          @change="updatedItem"
          class="w-100"
          v-model="item.collectionCost"
        />
      </b-td>
    </b-tr>
  </div>
</template>

<script>
import { BTr, BTh, BTd, BFormInput } from "bootstrap-vue";
import TailingZeroNumberInput from "@/app/components/layout/TailingZeroNumberInput.vue";

export default {
  props: {
    item: {
      required: true,
    },
    baseLine: {
      required: true,
    },
    postingCategoriesIncluded: {
      required: false,
    },
  },
  components: {
    BTr,
    BTh,
    BTd,
    BFormInput,
    TailingZeroNumberInput,
  },
  created() {
    if (this.item.tonsPrCollection == 0)
      this.item.tonsPrCollection = this.item.tonsPrCollection;
  },
  computed: {
    totalRent() {
      return this.item.monthlyRent * 12;
    },
    totalIncome() {
      return this.item.incomePrTon * this.item.tonsCollected;
    },
    totalCharge() {
      return this.item.chargePrTon * this.item.tonsCollected;
    },
    totalCollectionCost() {
      return this.item.collectionCost * this.item.noOfCollections;
    },
    totalCost() {
      let result =
        this.totalRent -
        this.totalIncome +
        this.totalCharge +
        this.totalCollectionCost;
      this.item["totalCost"] = result;
      return result;
    },
    totalCostPrTons() {
      if (this.item.tonsCollected > 0)
        return this.totalCost / this.item.tonsCollected;
      return 0;
    },
  },
  methods: {
    updatetonsPrCollection() {
      let result = 0;
      if (this.item.noOfCollections > 0)
        result = this.item.tonsCollected / this.item.noOfCollections;

      this.item.tonsPrCollection = result;
      this.updatedItem();
    },
    updateNoOfCollection() {
      let result =
        this.item.tonsPrCollection > 0
          ? this.item.tonsCollected / this.item.tonsPrCollection
          : 0;

      this.item.noOfCollections = result;
      this.updatedItem();
    },
    removeItem() {
      this.$emit("item-remove", this.item);
    },
    updatedItem() {
      this.$emit("item-change", this.item);
    },
  },
};
</script>
<style lang="scss" scoped>
input {
  border: 0px;
  font-weight: bold;
  text-align: center;
  width: 100%;

  &:focus {
    outline: 0;
    background-color: #dcdddf;
  }
  &.title-input {
  }
}
.title {
  position: relative;
  .edit-icon {
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
</style>