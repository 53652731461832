<template>
    <div class="title mb-1 w-100">
        <b-form-input v-model="content" autofocus v-if="titleEdit" @blur="handleTitleInputBlur"
            @focus="$event.target.select()" @keyup.enter="handleTitleInputBlur"/>
        <h1 v-else @click="titleEdit = true" class="d-flex">
            {{ content }}
            <feather-icon icon="Edit2Icon" class="edit-icon ml-1" />
        </h1>
    </div>
</template>
<script>
import { BFormInput } from 'bootstrap-vue';

export default {
    props: ['value'],
    components: {
        BFormInput
    },
    data() {
        return {
            titleEdit: false,
            content: this.value
        }
    },
    methods: {
        handleTitleInputBlur() {
            this.titleEdit = false;
            this.$emit("input", this.content);
            this.$emit("change", this.content);
        },
    }
}
</script>

<style lang="scss" scoped>
.title {
    .edit-icon {
        display: none;
    }

    &:hover {
        .edit-icon {
            display: block;
        }
    }
}
</style>